import React, { useState, useRef, useEffect } from "react"
import { graphql } from "gatsby"

import SEO from "components/seo"

import ServicesList from "components/servicesList"
import Button from 'components/button'
import ExtravertLogo from '../images/extravert-logo.png'

export default ({ data }) => {
    const [selectedServiceIndex, setSelectedService] = useState(0)
    const [delayedSelectedServiceIndex, setDelayedSelectedServiceIndex] = useState(0)
    const [transitionOperation, setTransitionOperation] = useState(null)
    const direction = useRef(null)
    
    const handleSelectedService = index => {
        const operation = index > selectedServiceIndex ? 'next' : 'prev'

        setTransitionOperation(operation)

        direction.current = index > selectedServiceIndex ? 'next' : 'prev' // needs tweaking

        setSelectedService(index)

        setTimeout(() => {
            direction.current = null
            
            setDelayedSelectedServiceIndex(index)
            setTransitionOperation(null)
        }, 400)
    }

    const services = data.siteFields.services
    const currentService = services.edges[delayedSelectedServiceIndex].node

    let excerpt = currentService.content || ''
    excerpt = excerpt.replace(/(<([^>]+)>)/ig,"")
    excerpt = excerpt.slice(0,160)

    const { edges } = services

    const visibleIndices = [direction.current === 'prev' ? selectedServiceIndex : null, delayedSelectedServiceIndex, direction.current === 'next' ? selectedServiceIndex : null]

	return (
		<>
            <SEO 
                title={currentService.title}
                description={excerpt}
            />
            <div className="service archive">
                <div className={'container'}>
                    <ServicesList 
                        services={services } 
                        selectedServiceIndex={selectedServiceIndex } 
                        handleSelectedService={handleSelectedService } 
                    />
                    <div className={`service-content ${ transitionOperation || '' }`}>
                        { visibleIndices.map((visibleIndex, index) => {
                            const service = edges[visibleIndex] ? edges[visibleIndex].node : {}
                            
                            const {
                                featuredImage,
                                title,
                                serviceFields,
                                slug,
                            } = service

                            return (
                                <div key={index} data-colour={slug || ''}>
                                    { title ?
                                        <>
                                            <div className='service-content-text'>
                                                <div className="title-row">
                                                    <h1 dangerouslySetInnerHTML={{ __html: title }} />
                                                    {
                                                        slug == 'shopper-marketing' || slug == 'digital-marketing-communications' || slug == 'experiential' ?
                                                            <img className="logo" src={ExtravertLogo}/>
                                                            : null
                                                    }
                                                </div>
                                                <div 
                                                    className="service-tagline" 
                                                    dangerouslySetInnerHTML={{ __html: serviceFields.tagline }} 
                                                />
                                                { featuredImage ? 
                                                    <img src={featuredImage.mediaItemUrl } alt={title } /> 
                                                : null }
                                            </div>
                                            <Button 
                                                url={'services/'+slug } 
                                            />
                                        </>
                                    : null }
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
		</>
	)
}

export const query = graphql`
    query($databaseId: ID!) {
        siteFields {
            service(id: $databaseId, idType: DATABASE_ID) {
                title
                content
                slug
            }
            services {
                edges {
                    node {
                        title
                        link
                        slug
                        content
                        featuredImage {
                            mediaItemUrl
                        }
                        serviceFields {
                            tagline
                        }
                    }
                }
            }
        }
    }
`
