import React from "react"

export default ({ services, selectedServiceIndex, handleSelectedService }) => {

    let _services = services.edges.map(_service => _service)

    return (
        <ul className="service-list">
            {_services.map((row, index) => {
                
                return (
                    <li dangerouslySetInnerHTML={{ __html: row.node.title }} key={index } className={index === selectedServiceIndex ? 'active' : '' } onClick={() => handleSelectedService(index) } data-colour={row.node.slug } />
                )
                
            })}
        </ul>
    )
}
